import type { SvgIconProps } from '@mui/material';
import { SvgIcon } from '@mui/material';

const VolleyballIcon = ({ ...props }: SvgIconProps) => (
  <SvgIcon xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" {...props}>
    <path d="M11.6499 3.00002C9.36626 2.99469 7.17358 3.89474 5.55225 5.50295C3.93091 7.11116 3.01322 9.29649 3 11.5801C3.01322 13.8637 3.93091 16.049 5.55225 17.6573C7.17358 19.2655 9.36626 20.1655 11.6499 20.1602C13.9335 20.1655 16.1262 19.2655 17.7476 17.6573C19.3689 16.049 20.2868 13.8637 20.3 11.5801C20.2868 9.29649 19.3689 7.11116 17.7476 5.50295C16.1262 3.89474 13.9335 2.99469 11.6499 3.00002ZM18.73 13.5298C17.8 12.1498 15.8099 10.3301 11.8599 10.7901C11.3125 10.0314 10.9216 9.17107 10.71 8.25979C15.71 6.63979 18.1599 9.34993 18.8999 10.3999C18.9634 10.7901 18.9969 11.1848 19 11.5801C18.9952 12.2292 18.9044 12.8748 18.73 13.5V13.5298ZM9.3999 14.0401C5.5399 10.5701 6.51985 7.14991 7.09985 5.87991C7.9457 5.21213 8.92956 4.74088 9.97998 4.50002C9.19998 6.03002 8.64005 8.67016 11.05 11.8902C10.6322 12.6875 10.073 13.4023 9.3999 14V14.0401ZM17.8398 7.66995C16.7685 7.01435 15.5642 6.60685 14.3149 6.47707C13.0657 6.3473 11.8031 6.49854 10.6199 6.91995C10.6856 5.96667 11.0337 5.05445 11.6199 4.29983C12.8535 4.29066 14.0696 4.59169 15.1565 5.17532C16.2434 5.75894 17.1661 6.60676 17.8398 7.64016V7.66995ZM5.25 8.02004C5.12 9.89004 5.68 12.3401 8.25 14.7901C7.31947 15.2431 6.27351 15.4034 5.25 15.25C4.59233 14.139 4.24684 12.8712 4.25 11.5801C4.26284 10.3196 4.60776 9.0847 5.25 8.00002V8.02004ZM6.32983 16.5899C8.05983 16.5099 10.6899 15.7098 12.4399 12.0298C13.3258 11.9477 14.2191 12.0394 15.0698 12.2998C14.2598 17.1398 11.07 18.2998 9.55005 18.5498C8.32002 18.182 7.20892 17.4957 6.32983 16.5601V16.5899ZM12.46 18.8198C13.4892 18.1624 14.3641 17.2906 15.0251 16.2637C15.6862 15.2368 16.1178 14.0792 16.29 12.8701C17.0992 13.4067 17.7293 14.1725 18.0999 15.0698C17.5219 16.0926 16.7091 16.9636 15.7285 17.6104C14.7479 18.2572 13.6276 18.6615 12.46 18.7901V18.8198Z" />
  </SvgIcon>
);

export default VolleyballIcon;
