import type { SvgIconProps } from '@mui/material';
import { SvgIcon } from '@mui/material';

const CricketIcon = ({ ...props }: SvgIconProps) => (
  <SvgIcon xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" {...props}>
    <path d="M10.9948 16.13L12.119 15.0043C12.3198 14.8134 12.6325 14.8217 12.8318 15.0043C13.0311 15.2119 13.0311 15.5192 12.8318 15.7185L11.7076 16.8427C11.5098 17.0404 11.201 17.0404 10.9948 16.8427C10.7955 16.6449 10.7955 16.3293 10.9948 16.13Z" />
    <path d="M13.3703 13.7529L14.496 12.6356C14.6953 12.4379 15.008 12.4379 15.2088 12.6356C15.4065 12.8364 15.4065 13.1437 15.2088 13.3484L14.083 14.4672C13.8853 14.6718 13.5711 14.6718 13.3703 14.4672C13.1725 14.2664 13.1725 13.9606 13.3703 13.7529Z" />
    <path d="M15.7473 11.3843L16.8715 10.2601C17.0708 10.0609 17.3796 10.0609 17.5774 10.2601C17.785 10.4594 17.785 10.7736 17.5774 10.9729L16.46 12.097C16.2608 12.2963 15.9466 12.2963 15.7473 12.097C15.5564 11.8909 15.548 11.5852 15.7473 11.3843Z" />
    <path d="M8.00021 13.1703L9.11603 12.0462C9.32369 11.8454 9.63099 11.8454 9.82876 12.0462C10.0212 12.2439 10.0296 12.5581 9.82876 12.7589L8.71144 13.883C8.50679 14.0755 8.19798 14.0755 8.00021 13.883C7.79942 13.6769 7.79942 13.3697 8.00021 13.1703Z" />
    <path d="M10.3688 10.7948L11.4946 9.66915C11.6923 9.46987 12.0065 9.46987 12.2058 9.66915C12.4051 9.86844 12.4051 10.1826 12.2058 10.3834L11.0801 11.5076C10.8823 11.7053 10.575 11.7053 10.3688 11.5076C10.1696 11.3068 10.1696 10.9926 10.3688 10.7948Z" />
    <path d="M12.7458 8.41785L13.87 7.29365C14.0678 7.09438 14.3835 7.09438 14.5813 7.29365C14.7821 7.49143 14.7821 7.80711 14.5813 8.00639L13.4586 9.13209C13.2578 9.32987 12.9436 9.3215 12.7458 9.13209C12.545 8.9313 12.545 8.61714 12.7458 8.41785Z" />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M21.3999 12.0001C21.3999 16.7774 17.5271 20.6501 12.7499 20.6501C7.97259 20.6501 4.09985 16.7774 4.09985 12.0001C4.09985 7.22283 7.97259 3.3501 12.7499 3.3501C17.5271 3.3501 21.3999 7.22283 21.3999 12.0001ZM20.1999 12.0001C20.1999 16.1146 16.8644 19.4501 12.7499 19.4501C11.6769 19.4501 10.6569 19.2233 9.73529 18.815L10.4563 18.094C10.654 17.8962 10.654 17.5874 10.4563 17.3881C10.257 17.1903 9.9413 17.182 9.74352 17.3881L8.80763 18.3228C8.52391 18.1455 8.25298 17.9497 7.99654 17.7369L18.4831 7.24244C18.6972 7.50015 18.8942 7.77253 19.0725 8.05785L18.1228 9.00884C17.925 9.20661 17.9334 9.52077 18.1228 9.72157C18.3221 9.91934 18.6377 9.91934 18.8287 9.72157L19.5647 8.98551C19.973 9.90718 20.1999 10.9271 20.1999 12.0001ZM7.12278 16.8827L17.636 6.37608C17.3809 6.15425 17.1105 5.94948 16.8267 5.76357L15.834 6.75358C15.6348 6.95437 15.3191 6.946 15.1213 6.75358C14.922 6.55581 14.922 6.24013 15.1213 6.04236L15.9147 5.25381C14.9541 4.8024 13.8815 4.5501 12.7499 4.5501C8.63533 4.5501 5.29985 7.88558 5.29985 12.0001C5.29985 13.1321 5.55234 14.2052 6.00406 15.166L6.7474 14.4217C6.94668 14.2239 7.25549 14.2239 7.45327 14.4217C7.64953 14.6209 7.65942 14.9351 7.45327 15.1344L6.51279 16.0762C6.69799 16.359 6.90191 16.6284 7.12278 16.8827Z"
    />
  </SvgIcon>
);

export default CricketIcon;
