import type { SvgIconProps } from '@mui/material';
import { SvgIcon } from '@mui/material';

const AthleticsIcon = ({ ...props }: SvgIconProps) => (
  <SvgIcon xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" {...props}>
    <path d="M12.2562 16.9749L10.9862 17.8949L10.9762 17.8749C10.6962 18.0749 10.3962 17.8649 10.5062 17.5349L11.0162 16.0449L9.73622 15.1349C9.46622 14.9449 9.55622 14.5749 9.90622 14.5949H11.4762L11.9562 13.0949C12.0562 12.7649 12.4262 12.7649 12.5262 13.0949L13.0062 14.5849H14.5762C14.9362 14.5849 15.0162 14.9549 14.7562 15.1349L13.4762 16.0549L13.9862 17.5549C14.0962 17.8949 13.7962 18.1049 13.5062 17.8949L12.2562 16.9749Z" />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M16.9814 3.59497H7.51107L5.35779 6.84087L5.35121 6.85127C5.12943 7.20243 5.10508 7.65194 5.29942 8.02119L8.11257 13.1046C7.67685 13.8292 7.42627 14.6778 7.42627 15.5849C7.42627 18.2469 9.58426 20.4049 12.2463 20.4049C14.9083 20.4049 17.0663 18.2469 17.0663 15.5849C17.0663 14.6778 16.8157 13.8292 16.3799 13.1045L19.1875 8.03128L19.1881 8.03003C19.3923 7.6588 19.3842 7.19451 19.1295 6.83336L16.9814 3.59497ZM15.6427 12.1649L18.2243 7.49992C18.2309 7.48783 18.2321 7.47801 18.2318 7.47302C18.2316 7.46864 18.2304 7.46703 18.2304 7.46703L18.224 7.45812L16.9311 5.50915L14.0249 11.1037C14.6353 11.3462 15.1845 11.7098 15.6427 12.1649ZM12.9359 10.8139C12.7107 10.7816 12.4804 10.7649 12.2463 10.7649C12.0025 10.7649 11.7629 10.783 11.5289 10.8179L10.1195 8.10488H14.3431L12.9359 10.8139ZM14.9146 7.00488H9.54805L8.3481 4.69497H16.1145L14.9146 7.00488ZM10.4429 11.1136L7.54459 5.53439L6.27957 7.44145C6.27271 7.45333 6.26877 7.46697 6.26814 7.48052C6.26754 7.49344 6.27007 7.50236 6.27209 7.50703L8.84976 12.1649C9.30179 11.716 9.84232 11.356 10.4429 11.1136ZM8.62627 15.5849C8.62627 13.5856 10.247 11.9649 12.2463 11.9649C14.2455 11.9649 15.8663 13.5856 15.8663 15.5849C15.8663 17.5842 14.2455 19.2049 12.2463 19.2049C10.247 19.2049 8.62627 17.5842 8.62627 15.5849Z"
    />
  </SvgIcon>
);

export default AthleticsIcon;
