import type { SvgIconProps } from '@mui/material';
import { SvgIcon } from '@mui/material';

const RugbyIcon = ({ ...props }: SvgIconProps) => {
  return (
    <SvgIcon
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      {...props}
    >
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M12.0406 20.4267C11.4241 20.649 10.8099 20.8195 10.21 20.9391C7.86893 21.4055 5.56164 21.1221 4.26025 19.8513C2.95117 18.5721 2.66787 16.3495 3.13286 14.0759C3.60597 11.7627 4.87971 9.21671 6.9506 7.0946C9.02479 4.9698 11.5346 3.675 13.8377 3.18067C16.1066 2.6937 18.3232 2.96033 19.6571 4.18711C20.9665 5.39083 21.3333 7.63864 20.9511 9.94664C20.5612 12.3016 19.37 14.9163 17.2877 17.0494C15.743 18.6318 13.907 19.7458 12.088 20.4095L12.0811 20.4369L12.0406 20.4267ZM7.881 8.00254C9.10243 6.75133 10.483 5.82204 11.8585 5.20129C13.5177 5.64673 14.8283 6.33628 15.8541 7.13159L10.7224 12.4526C10.5307 12.6513 10.5365 12.9679 10.7352 13.1596C10.934 13.3513 11.2505 13.3455 11.4422 13.1468L16.6127 7.7855C18.0754 9.18665 18.7955 10.779 19.0658 11.9244C18.504 13.3845 17.6101 14.8581 16.3575 16.1413C14.9169 17.617 13.1967 18.642 11.5125 19.2348C9.87994 18.7107 8.61448 17.9422 7.64494 17.0842L9.5365 15.1228C9.7282 14.924 9.72246 14.6075 9.52369 14.4158C9.32493 14.2241 9.0084 14.2299 8.8167 14.4286L6.93213 16.3827C5.73824 15.0753 5.13588 13.6648 4.90314 12.6345C5.49929 11.0556 6.48933 9.42863 7.881 8.00254ZM19.5626 10.2822C18.6979 8.2939 16.8438 5.93334 13.3772 4.63764C13.6242 4.56552 13.869 4.50357 14.1105 4.45172C16.1948 4.00437 17.8928 4.33062 18.7772 5.14407C19.6023 5.90243 20.0244 7.58526 19.6686 9.73428C19.6386 9.91509 19.6033 10.0979 19.5626 10.2822ZM4.41105 14.3143L4.4065 14.3364C3.97647 16.439 4.32847 18.1002 5.16849 18.9212C6.0006 19.7337 7.72854 20.0905 9.87526 19.6799C6.86375 18.3372 5.20974 16.1625 4.41105 14.3143Z"
      />
    </SvgIcon>
  );
};

export default RugbyIcon;
