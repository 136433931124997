import type { ReactNode } from 'react';

import type { SvgIconProps } from '@mui/material';
import { SvgIcon } from '@mui/material';

const GradientIconMacker = ({
  children,
  sx,
  ...props
}: { children: ReactNode } & SvgIconProps) => (
  <SvgIcon sx={{ svg: { fill: 'url(#applyGradient)' }, ...sx }} {...props}>
    {children}
    <defs>
      <linearGradient
        id="applyGradient"
        x1="2.14773"
        y1="-3.5"
        x2="24.344"
        y2="12.0406"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset="0.125995" stopColor="#37C556" />
        <stop offset="1" stopColor="#15B3B3" />
      </linearGradient>
    </defs>
  </SvgIcon>
);

export default GradientIconMacker;
