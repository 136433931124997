import type { SvgIconProps } from '@mui/material';
import { SvgIcon } from '@mui/material';

const IceHockeyIcon = ({ ...props }: SvgIconProps) => (
  <SvgIcon xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" {...props}>
    <path d="M12.75 5.69507C7.9 5.69507 4.25 7.37507 4.25 9.60507V14.3951C4.25 16.6251 7.9 18.3051 12.75 18.3051C17.6 18.3051 21.25 16.6251 21.25 14.3951V9.60507C21.25 7.37507 17.6 5.69507 12.75 5.69507ZM12.75 6.89507C16.93 6.89507 20.05 8.32507 20.05 9.60507C20.05 10.8851 16.93 12.3151 12.75 12.3151C8.57 12.3151 5.45 10.8851 5.45 9.60507C5.45 8.32507 8.57 6.89507 12.75 6.89507ZM12.75 17.1151C8.57 17.1151 5.45 15.6851 5.45 14.4051V11.6751C6.91 12.8051 9.57 13.5351 12.75 13.5351C15.93 13.5351 18.59 12.8051 20.05 11.6751V14.4051C20.05 15.6851 16.93 17.1151 12.75 17.1151Z" />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M12.7498 8.73467C12.2774 8.73467 11.823 8.75324 11.3972 8.7875L11.3009 7.59137C11.7598 7.55445 12.2463 7.53467 12.7498 7.53467C13.2533 7.53467 13.7398 7.55445 14.1987 7.59137C14.7027 7.63192 15.1756 7.69334 15.6024 7.7727C16.1048 7.86613 16.5566 7.98687 16.9241 8.13456C17.1881 8.24066 17.4402 8.37305 17.6365 8.54301C17.8298 8.71041 18.0372 8.97702 18.0372 9.34063C18.0372 9.6735 17.8622 9.92633 17.6869 10.0925C17.5121 10.2583 17.2885 10.3872 17.0577 10.4902C16.7342 10.6345 16.3375 10.7538 15.8973 10.8493L15.6428 9.67655C16.0343 9.59161 16.3455 9.49389 16.5688 9.39427C16.6107 9.37557 16.6477 9.3576 16.6802 9.34063C16.6251 9.31182 16.5578 9.28061 16.4767 9.24801C16.2064 9.1394 15.8372 9.03693 15.383 8.95247C15.0022 8.88166 14.5708 8.82519 14.1024 8.7875C13.6766 8.75324 13.2222 8.73467 12.7498 8.73467ZM16.8738 9.4724C16.8737 9.4724 16.8731 9.47168 16.872 9.47025C16.8733 9.47168 16.8738 9.4724 16.8738 9.4724ZM10.1166 8.95247C9.70736 9.02858 9.3659 9.11952 9.10337 9.21697C8.98637 9.2604 8.89269 9.30234 8.81943 9.34063C8.89269 9.37891 8.98637 9.42086 9.10337 9.46429C9.3659 9.56174 9.70736 9.65268 10.1166 9.72878C10.455 9.7917 10.8334 9.84333 11.2425 9.8805C11.7122 9.92319 12.2196 9.94659 12.7498 9.94659C13.28 9.94659 13.7874 9.92319 14.2571 9.8805L14.3657 11.0756C13.8585 11.1217 13.3147 11.1466 12.7498 11.1466C12.1849 11.1466 11.6411 11.1217 11.1339 11.0756C10.6924 11.0355 10.2767 10.9791 9.89723 10.9086C9.44261 10.824 9.03083 10.7174 8.68578 10.5893C8.39368 10.4809 8.118 10.3457 7.90419 10.1726C7.69696 10.0048 7.4624 9.7283 7.4624 9.34063C7.4624 8.95295 7.69696 8.67642 7.90419 8.50865C8.118 8.33555 8.39368 8.2004 8.68578 8.09197C9.03083 7.96389 9.44261 7.85724 9.89723 7.7727L10.1166 8.95247ZM8.62554 9.20859C8.62564 9.20858 8.6267 9.20974 8.6284 9.21206C8.62628 9.20975 8.62543 9.20859 8.62554 9.20859ZM8.6284 9.46919C8.6267 9.47152 8.62564 9.47267 8.62554 9.47267C8.62543 9.47267 8.62628 9.4715 8.6284 9.46919Z"
    />
  </SvgIcon>
);

export default IceHockeyIcon;
