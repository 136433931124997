import type { SvgIconProps } from '@mui/material';
import { SvgIcon } from '@mui/material';

const UniversalIcon = ({ ...props }: SvgIconProps) => (
  <SvgIcon xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" {...props}>
    <path d="M11.3451 15.1301V13.9401H12.5451V15.1301H11.3451Z" />
    <path d="M11.3451 10.5601V11.7501H12.5451V10.5601H11.3451Z" />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M18.5848 10.3601C18.4248 10.2001 18.2248 10.1201 17.9848 10.1201H15.4848C15.2448 10.1201 15.0448 10.2001 14.8848 10.3601C14.7248 10.5201 14.6448 10.7201 14.6448 10.9601V14.9601C14.6448 15.2001 14.7248 15.4001 14.8848 15.5601C15.0448 15.7201 15.2448 15.8001 15.4848 15.8001H17.9848C18.2248 15.8001 18.4248 15.7201 18.5848 15.5601C18.7448 15.4001 18.8248 15.2001 18.8248 14.9601V10.9601C18.8248 10.7201 18.7448 10.5201 18.5848 10.3601ZM17.6348 14.6201H15.8248V11.3101H17.6348V14.6201Z"
    />
    <path d="M5.55493 13.4101V15.8101H9.74493V14.6201H6.74493V13.4101H8.86493C9.10493 13.4101 9.30494 13.3301 9.48494 13.1501C9.65494 12.9801 9.74493 12.7701 9.74493 12.5301V10.9701C9.74493 10.7301 9.66493 10.5301 9.50493 10.3701C9.34493 10.2101 9.14494 10.1301 8.90494 10.1301H5.55493V11.3201H8.55493V12.5301H6.43494C6.19494 12.5301 5.99494 12.6101 5.81494 12.7901C5.64494 12.9601 5.55493 13.1701 5.55493 13.4101Z" />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M15.3851 5.79011H8.50511V3.86011H7.30511V5.79011H4.5351C3.67906 5.79011 2.98511 6.48407 2.98511 7.34011V18.5901C2.98511 19.4461 3.67906 20.1401 4.5351 20.1401H19.4651C20.3212 20.1401 21.0151 19.4461 21.0151 18.5901V7.34011C21.0151 6.48407 20.3212 5.79011 19.4651 5.79011H16.5851V3.86011H15.3851V5.79011ZM4.5351 6.99011C4.34181 6.99011 4.18511 7.14681 4.18511 7.34011V18.5901C4.18511 18.7834 4.34181 18.9401 4.5351 18.9401H11.3451V17.3401H12.5451V18.9401H19.4651C19.6584 18.9401 19.8151 18.7834 19.8151 18.5901V7.34011C19.8151 7.14681 19.6584 6.99011 19.4651 6.99011H12.5451V8.36011H11.3451V6.99011H4.5351Z"
    />
  </SvgIcon>
);

export default UniversalIcon;
