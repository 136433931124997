import type { SvgIconProps } from '@mui/material';
import { SvgIcon } from '@mui/material';

const BadmintonIcon = ({ ...props }: SvgIconProps) => (
  <SvgIcon xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" {...props}>
    <path d="M15.1701 15.9102H14.5602H11.8102C10.8802 15.9102 9.95022 15.9102 9.06023 15.9102H8.45012V16.5303C8.33894 17.4749 8.55811 18.429 9.07023 19.2305C9.3815 19.6743 9.79697 20.0346 10.2802 20.2803C10.7623 20.53 11.2973 20.6602 11.8403 20.6602C12.3801 20.6614 12.9122 20.5311 13.3903 20.2803C13.8665 20.0465 14.281 19.7039 14.6003 19.2803C15.1243 18.4801 15.3441 17.5187 15.2201 16.5703L15.1701 15.9102ZM13.5202 18.4702C13.3285 18.7425 13.0749 18.9657 12.7802 19.1206C12.4703 19.2769 12.1272 19.3558 11.7802 19.3506C11.4331 19.357 11.0897 19.278 10.7802 19.1206C10.4796 18.9704 10.2216 18.7465 10.0302 18.4702C9.7778 18.0983 9.64212 17.6599 9.6403 17.2104H11.7602H13.8503C13.8687 17.654 13.7537 18.0927 13.5202 18.4702Z" />
    <path d="M17.8599 3.52031C17.1738 3.32848 16.4484 3.32647 15.7615 3.51494C15.0745 3.70341 14.4518 4.07509 13.9597 4.59014C13.7077 4.24843 13.3836 3.96667 13.0102 3.76445C12.6369 3.56224 12.2237 3.4446 11.7998 3.42022C11.3582 3.44401 10.9256 3.5551 10.5273 3.74736C10.1291 3.93963 9.77303 4.20935 9.47972 4.54033C9.14211 4.17534 8.7339 3.8824 8.27977 3.67998C7.51608 3.35735 6.66383 3.31149 5.86986 3.5501C5.58729 3.63551 5.32466 3.77713 5.09789 3.96611C4.87111 4.15509 4.68476 4.38766 4.54979 4.6502C4.31335 5.19889 4.29188 5.81642 4.48973 6.38018L8.48973 15.6004H15.2297L19.2297 6.54033V6.40996C19.4158 5.84447 19.3773 5.22914 19.1223 4.69121C18.8673 4.15328 18.4154 3.73427 17.8599 3.52031ZM11.7698 4.72002C12.2498 4.72002 12.7697 5.10033 13.1997 5.79033L12.7998 9.09014H10.5398L10.0798 5.79033C10.2749 5.50728 10.525 5.2662 10.8154 5.08232C11.1058 4.89845 11.4305 4.77526 11.7698 4.72002ZM12.6697 10.3899L12.1897 14.3001H11.2798L10.7397 10.3899H12.6697ZM5.71971 5.21025C5.77914 5.10742 5.85847 5.0172 5.95287 4.94512C6.04727 4.87303 6.15491 4.82059 6.26976 4.79033C6.47452 4.73103 6.68672 4.7004 6.89989 4.7C7.19478 4.70125 7.48657 4.75937 7.75975 4.87041C8.19406 5.0846 8.54556 5.4361 8.75975 5.87041L9.2097 9.13018H7.03978L5.65966 5.95C5.59652 5.70355 5.6177 5.44332 5.71971 5.21025ZM7.63988 10.3899H9.42968L9.96971 14.3001H9.32982L7.63988 10.3899ZM14.3599 14.3001H13.4997L13.9797 10.3899H16.0698L14.3599 14.3001ZM17.9497 6.08037L16.6299 9.08037H14.1299L14.5198 5.87041C14.8522 5.4226 15.3051 5.07831 15.8257 4.87822C16.3462 4.67814 16.9129 4.63015 17.4597 4.74004C17.5697 4.79004 18.1197 5.05037 17.9497 6.08037Z" />
  </SvgIcon>
);

export default BadmintonIcon;
