import type { SvgIconProps } from '@mui/material';
import { SvgIcon } from '@mui/material';

const GolfIcon = ({ ...props }: SvgIconProps) => (
  <SvgIcon xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" {...props}>
    <path d="M11.4595 6.21094C11.2977 6.21094 11.1427 6.27525 11.0283 6.38965C10.9139 6.50405 10.8496 6.65902 10.8496 6.8208C10.8496 6.98258 10.9139 7.13756 11.0283 7.25195C11.1427 7.36635 11.2977 7.43066 11.4595 7.43066C11.6213 7.43066 11.7765 7.36635 11.8909 7.25195C12.0053 7.13756 12.0696 6.98258 12.0696 6.8208C12.067 6.65982 12.0018 6.50642 11.8879 6.39258C11.7741 6.27873 11.6205 6.21351 11.4595 6.21094Z" />
    <path d="M13.1899 7.90039C13.0282 7.90039 12.8732 7.9647 12.7588 8.0791C12.6444 8.1935 12.5801 8.34847 12.5801 8.51025C12.5801 8.67204 12.6444 8.8275 12.7588 8.94189C12.8732 9.05629 13.0282 9.12061 13.1899 9.12061C13.3517 9.12061 13.5069 9.05629 13.6213 8.94189C13.7357 8.8275 13.8 8.67204 13.8 8.51025C13.8 8.34847 13.7357 8.1935 13.6213 8.0791C13.5069 7.9647 13.3517 7.90039 13.1899 7.90039Z" />
    <path d="M14.9302 9.58984C14.7684 9.58984 14.6134 9.65416 14.499 9.76855C14.3846 9.88295 14.3203 10.0379 14.3203 10.1997C14.3203 10.3615 14.3846 10.517 14.499 10.6313C14.6134 10.7457 14.7684 10.8101 14.9302 10.8101C15.092 10.8101 15.2472 10.7457 15.3616 10.6313C15.476 10.517 15.5403 10.3615 15.5403 10.1997C15.5403 10.0379 15.476 9.88295 15.3616 9.76855C15.2472 9.65416 15.092 9.58984 14.9302 9.58984Z" />
    <path d="M13.4497 5.85938C13.2879 5.85938 13.133 5.92369 13.0186 6.03809C12.9042 6.15248 12.8398 6.30746 12.8398 6.46924C12.8398 6.63102 12.9042 6.78648 13.0186 6.90088C13.133 7.01528 13.2879 7.07959 13.4497 7.07959C13.6115 7.07959 13.7667 7.01528 13.8811 6.90088C13.9955 6.78648 14.0598 6.63102 14.0598 6.46924C14.0598 6.30746 13.9955 6.15248 13.8811 6.03809C13.7667 5.92369 13.6115 5.85938 13.4497 5.85938Z" />
    <path d="M15.1899 7.55078C15.0282 7.55078 14.8732 7.61509 14.7588 7.72949C14.6444 7.84389 14.5801 7.99886 14.5801 8.16064C14.5801 8.32243 14.6444 8.47789 14.7588 8.59229C14.8732 8.70668 15.0282 8.771 15.1899 8.771C15.2696 8.77101 15.3484 8.7549 15.4219 8.72412C15.4953 8.69334 15.5621 8.64858 15.6179 8.5918C15.6738 8.53502 15.7178 8.46751 15.7473 8.39355C15.7769 8.3196 15.7914 8.24028 15.79 8.16064C15.7914 8.08101 15.7769 8.00218 15.7473 7.92822C15.7178 7.85427 15.6738 7.78676 15.6179 7.72998C15.5621 7.6732 15.4953 7.62843 15.4219 7.59766C15.3484 7.56688 15.2696 7.55077 15.1899 7.55078Z" />
    <path d="M11.7795 3.38086C10.075 3.3835 8.44113 4.0618 7.23584 5.26709C6.03055 6.47238 5.35225 8.10651 5.34961 9.81104C5.35225 11.5156 6.03055 13.1492 7.23584 14.3545C8.44113 15.5598 10.075 16.2381 11.7795 16.2407C13.4858 16.2407 15.1224 15.564 16.3298 14.3584C17.5373 13.1528 18.2168 11.5173 18.2195 9.81104C18.2142 8.10559 17.5338 6.4716 16.3269 5.2666C15.12 4.0616 13.485 3.3835 11.7795 3.38086ZM11.7795 14.9409C10.419 14.9409 9.11416 14.4005 8.1521 13.4385C7.19004 12.4764 6.64966 11.1716 6.64966 9.81104C6.64966 9.13735 6.78223 8.47006 7.04004 7.84766C7.29785 7.22526 7.67573 6.65996 8.1521 6.18359C8.62846 5.70723 9.19401 5.3291 9.81641 5.07129C10.4388 4.81348 11.1059 4.68066 11.7795 4.68066C13.141 4.68066 14.447 5.22084 15.4106 6.18262C16.3743 7.14439 16.9168 8.44956 16.9194 9.81104C16.9089 11.1673 16.3627 12.4641 15.3999 13.4194C14.4371 14.3747 13.1359 14.9107 11.7795 14.9106V14.9409Z" />
    <path d="M14.5396 16.5304C13.679 16.8503 12.7679 17.0129 11.8497 17.0103H11.7696C10.7388 17.0447 9.71341 16.8464 8.76963 16.4303C8.609 16.3673 8.43021 16.3698 8.27134 16.4371C8.11246 16.5044 7.98604 16.6314 7.91953 16.7906C7.85652 16.9512 7.85908 17.1298 7.92636 17.2887C7.99365 17.4475 8.12043 17.5737 8.27964 17.6402C9.1883 18.0094 10.1509 18.2293 11.1297 18.2906V20.0103C11.1323 20.1819 11.2015 20.346 11.3228 20.4674C11.4442 20.5887 11.608 20.6577 11.7796 20.6603C11.952 20.6603 12.1175 20.5922 12.2394 20.4703C12.3613 20.3484 12.4295 20.1827 12.4295 20.0103V18.2804C13.3026 18.2314 14.1644 18.06 14.9896 17.7706C15.1519 17.7098 15.2838 17.5874 15.3568 17.4303C15.4298 17.2731 15.438 17.0935 15.3797 16.9303C15.3209 16.7662 15.1995 16.6322 15.0421 16.5572C14.8847 16.4823 14.7041 16.4726 14.5396 16.5304Z" />
  </SvgIcon>
);

export default GolfIcon;
