import type { SvgIconProps } from '@mui/material';

import type { SportType } from 'types/scoreboardData';

import GradientIconMacker from '../GradientIconMacker';
import AllIcon from './AllIcon';
import AthleticsIcon from './AthleticsIcon';
import BadmintonIcon from './BadmintonIcon';
import BaseballIcon from './BaseballIcon';
import BasketballIcon from './BasketballIcon';
import BilliardIcon from './BilliardIcon';
import BodybuildingIcon from './BodybuildingIcon';
import CricketIcon from './CricketIcon';
import EsportsIcon from './EsportsIcon';
import FootballIcon from './FootballIcon';
import FutsalIcon from './FutsalIcon';
import GolfIcon from './GolfIcon';
import HandballIcon from './HandballIcon';
import HockeyIcon from './HockeyIcon';
import IceHockeyIcon from './IceHockeyIcon';
import JokguIcon from './JokguIcon';
import PickleballIcon from './PickleballIcon';
import RugbyIcon from './RugbyIcon';
import SepakTakrawIcon from './SepakTakrawIcon';
import SoccerIcon from './SoccerIcon';
import SquashIcon from './SquashIcon';
import TableTennisIcon from './TableTennisIcon';
import TaekwondoIcon from './TaekwondoIcon';
import TennisIcon from './TennisIcon';
import UniversalIcon from './UniversalIcon';
import VolleyballIcon from './VolleyballIcon';

const sportTypeIconList = {
  all: AllIcon,
  badminton: BadmintonIcon,
  baseball: BaseballIcon,
  basketball: BasketballIcon,
  billiard: BilliardIcon,
  cricket: CricketIcon,
  football: FootballIcon,
  futsal: FutsalIcon,
  golf: GolfIcon,
  handball: HandballIcon,
  jokgu: JokguIcon,
  pickleball: PickleballIcon,
  rugby: RugbyIcon,
  sepakTakraw: SepakTakrawIcon,
  soccer: SoccerIcon,
  squash: SquashIcon,
  tableTennis: TableTennisIcon,
  taekwondo: TaekwondoIcon,
  tennis: TennisIcon,
  volleyball: VolleyballIcon,
  universal: UniversalIcon,
  athletics: AthleticsIcon,
  esports: EsportsIcon,
  hockey: HockeyIcon,
  iceHockey: IceHockeyIcon,
  bodybuilding: BodybuildingIcon,
  climbing: UniversalIcon,
};

const SportsIcon = ({
  applyGradientColor = false,
  sportType,
  ...rest
}: { applyGradientColor?: boolean; sportType: SportType } & SvgIconProps) => {
  const IconComponent = sportTypeIconList[sportType] || null;

  return IconComponent ? (
    applyGradientColor ? (
      <GradientIconMacker {...rest}>
        <IconComponent />
      </GradientIconMacker>
    ) : (
      <IconComponent {...rest} />
    )
  ) : null;
};

export default SportsIcon;
