import type { SvgIconProps } from '@mui/material';
import { SvgIcon } from '@mui/material';

const HockeyIcon = ({ ...props }: SvgIconProps) => (
  <SvgIcon xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" {...props}>
    <path d="M12.1499 3.80005C9.85659 3.8027 7.65799 4.71505 6.03638 6.33667C4.41476 7.95829 3.50265 10.1566 3.5 12.45C3.50265 14.7433 4.41476 16.9421 6.03638 18.5637C7.65799 20.1853 9.85659 21.0972 12.1499 21.0999C14.4432 21.0972 16.6418 20.1853 18.2634 18.5637C19.885 16.9421 20.7974 14.7433 20.8 12.45C20.7974 10.1566 19.885 7.95829 18.2634 6.33667C16.6418 4.71505 14.4432 3.8027 12.1499 3.80005ZM12.1499 19.8C10.2014 19.7974 8.33339 19.0221 6.95557 17.6443C5.57775 16.2665 4.80269 14.3985 4.80005 12.45C4.80269 10.5014 5.57775 8.63343 6.95557 7.25562C8.33339 5.8778 10.2014 5.1025 12.1499 5.09985C14.0984 5.1025 15.9664 5.8778 17.3442 7.25562C18.7221 8.63343 19.4974 10.5014 19.5 12.45C19.4974 14.3985 18.7221 16.2665 17.3442 17.6443C15.9664 19.0221 14.0984 19.7974 12.1499 19.8Z" />
    <path d="M7.21997 11C6.89549 11.0026 6.5852 11.1334 6.35669 11.3638C6.12818 11.5942 5.99999 11.9055 6 12.23C6 12.5535 6.12838 12.864 6.35718 13.0928C6.58597 13.3216 6.89641 13.4497 7.21997 13.4497C7.54446 13.4497 7.8558 13.3218 8.08618 13.0933C8.31657 12.8648 8.44731 12.5545 8.44995 12.23C8.44995 11.9038 8.32027 11.591 8.0896 11.3604C7.85893 11.1297 7.54619 11 7.21997 11Z" />
    <path d="M15.22 15C14.8955 15.0026 14.5852 15.1334 14.3567 15.3638C14.1282 15.5942 14 15.9055 14 16.23C14 16.5535 14.1284 16.864 14.3572 17.0928C14.586 17.3216 14.8964 17.4497 15.22 17.4497C15.5445 17.4497 15.8558 17.3218 16.0862 17.0933C16.3166 16.8648 16.4473 16.5545 16.45 16.23C16.45 15.9038 16.3203 15.591 16.0896 15.3604C15.8589 15.1297 15.5462 15 15.22 15Z" />
    <path d="M17.22 11C16.8955 11.0026 16.5852 11.1334 16.3567 11.3638C16.1282 11.5942 16 11.9055 16 12.23C16 12.5535 16.1284 12.864 16.3572 13.0928C16.586 13.3216 16.8964 13.4497 17.22 13.4497C17.5445 13.4497 17.8558 13.3218 18.0862 13.0933C18.3166 12.8648 18.4473 12.5545 18.45 12.23C18.45 11.9038 18.3203 11.591 18.0896 11.3604C17.8589 11.1297 17.5462 11 17.22 11Z" />
    <path d="M10.22 15C9.89549 15.0026 9.5852 15.1334 9.35669 15.3638C9.12818 15.5942 8.99999 15.9055 9 16.23C9 16.5535 9.12838 16.864 9.35718 17.0928C9.58597 17.3216 9.89641 17.4497 10.22 17.4497C10.5445 17.4497 10.8558 17.3218 11.0862 17.0933C11.3166 16.8648 11.4473 16.5545 11.45 16.23C11.45 15.9038 11.3203 15.591 11.0896 15.3604C10.8589 15.1297 10.5462 15 10.22 15Z" />
    <path d="M14.22 7C13.8955 7.00264 13.5852 7.13339 13.3567 7.36377C13.1282 7.59415 13 7.90549 13 8.22998C13 8.55354 13.1284 8.86398 13.3572 9.09277C13.586 9.32157 13.8964 9.44971 14.22 9.44971C14.5445 9.44972 14.8558 9.32177 15.0862 9.09326C15.3166 8.86475 15.4473 8.55446 15.45 8.22998C15.45 7.90376 15.3203 7.59102 15.0896 7.36035C14.8589 7.12968 14.5462 7 14.22 7Z" />
    <path d="M12.22 11C11.8955 11.0026 11.5852 11.1334 11.3567 11.3638C11.1282 11.5942 11 11.9055 11 12.23C11 12.5535 11.1284 12.864 11.3572 13.0928C11.586 13.3216 11.8964 13.4497 12.22 13.4497C12.5445 13.4497 12.8558 13.3218 13.0862 13.0933C13.3166 12.8648 13.4473 12.5545 13.45 12.23C13.45 11.9038 13.3203 11.591 13.0896 11.3604C12.8589 11.1297 12.5462 11 12.22 11Z" />
    <path d="M9.21997 7C8.89549 7.00264 8.5852 7.13339 8.35669 7.36377C8.12818 7.59415 7.99999 7.90549 8 8.22998C8 8.55354 8.12838 8.86398 8.35718 9.09277C8.58597 9.32157 8.89641 9.44971 9.21997 9.44971C9.54446 9.44972 9.8558 9.32177 10.0862 9.09326C10.3166 8.86475 10.4473 8.55446 10.45 8.22998C10.45 7.90376 10.3203 7.59102 10.0896 7.36035C9.85893 7.12968 9.54619 7 9.21997 7Z" />
  </SvgIcon>
);

export default HockeyIcon;
