import type { SvgIconProps } from '@mui/material';
import { SvgIcon } from '@mui/material';

const SoccerIcon = ({ ...props }: SvgIconProps) => (
  <SvgIcon xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" {...props}>
    <path d="M11.9995 3.38086C9.7062 3.38351 7.5076 4.29586 5.88599 5.91748C4.26437 7.5391 3.35226 9.73745 3.34961 12.0308C3.35226 14.3241 4.26437 16.5229 5.88599 18.1445C7.5076 19.7661 9.7062 20.678 11.9995 20.6807C14.2928 20.678 16.4914 19.7661 18.113 18.1445C19.7347 16.5229 20.647 14.3241 20.6497 12.0308C20.647 9.73745 19.7347 7.5391 18.113 5.91748C16.4914 4.29586 14.2928 3.38351 11.9995 3.38086ZM18.3896 15.6509L15.8296 16.2007L14.5396 14.5308L15.5396 11.4307L17.5896 10.7808L19.3096 12.7808C19.2007 13.7805 18.8876 14.7474 18.3896 15.6211V15.6509ZM8.30957 16.2007L5.62964 15.6709C5.09449 14.7544 4.77298 13.7289 4.68945 12.6709L6.44946 10.6709L8.44946 11.4106L9.44946 14.5308L8.30957 16.2007ZM5.72949 9.84082L4.72949 10.9707C4.86455 10.0354 5.18036 9.1354 5.65942 8.3208L5.72949 9.84082ZM10.4995 14.0811L9.55957 11.2007L12.0095 9.4209L14.4595 11.2007L13.5295 14.0811H10.4995ZM18.3496 9.9707L18.4895 8.58105C18.8951 9.34481 19.1626 10.1739 19.2795 11.0308L18.3496 9.9707ZM17.5195 7.19092L17.2695 9.73096L15.2095 10.3906L12.5596 8.46094V6.271L15.0496 5.35107C15.9886 5.77812 16.8294 6.39386 17.5195 7.16064V7.19092ZM13.3396 4.81104L12.0396 5.29102L10.8997 4.771C11.2638 4.71571 11.6312 4.68531 11.9995 4.68066C12.4483 4.6734 12.8969 4.70697 13.3396 4.78076V4.81104ZM9.22949 5.23096L11.4595 6.23096V8.46094L8.82959 10.3809L6.82959 9.65088L6.72949 6.94092C7.44431 6.1841 8.31418 5.59034 9.27954 5.20068L9.22949 5.23096ZM6.5896 16.981L8.20947 17.3008L9.13965 18.8008C8.16448 18.3782 7.29394 17.7466 6.5896 16.9507V16.981ZM10.7195 19.2607L9.20947 16.8311L10.3796 15.1807H13.6594L14.9395 16.8511L13.5396 19.2207C13.0328 19.3249 12.5169 19.3787 11.9995 19.3809C11.569 19.3717 11.1404 19.3215 10.7195 19.231V19.2607ZM15.1396 18.6709L15.9595 17.3008L17.4194 16.981C16.7714 17.6737 15.9979 18.2367 15.1396 18.6406V18.6709Z" />
  </SvgIcon>
);

export default SoccerIcon;
