import type { SvgIconProps } from '@mui/material';
import { SvgIcon } from '@mui/material';

const TennisIcon = ({ ...props }: SvgIconProps) => (
  <SvgIcon xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" {...props}>
    <path d="M11.9995 3.38086C9.7062 3.38351 7.5076 4.29586 5.88599 5.91748C4.26437 7.5391 3.35226 9.73745 3.34961 12.0308C3.35226 14.3241 4.26437 16.5229 5.88599 18.1445C7.5076 19.7661 9.7062 20.678 11.9995 20.6807C14.2928 20.678 16.4914 19.7661 18.113 18.1445C19.7347 16.5229 20.647 14.3241 20.6497 12.0308C20.647 9.73745 19.7347 7.5391 18.113 5.91748C16.4914 4.29586 14.2928 3.38351 11.9995 3.38086ZM19.3196 11.3809C17.6119 11.2224 16.0132 10.4724 14.7996 9.26074C13.5887 8.05164 12.8417 6.45536 12.6895 4.75098C14.3766 4.91945 15.9538 5.66522 17.1545 6.8623C18.3553 8.05939 19.1059 9.6342 19.2795 11.3208L19.3196 11.3809ZM4.67944 12.5708C6.44764 12.6873 8.11394 13.4406 9.36963 14.6909C10.6229 15.9456 11.3794 17.6115 11.4995 19.3809C9.72274 19.2581 8.05114 18.4934 6.79639 17.2295C5.54163 15.9655 4.78925 14.2884 4.67944 12.5107V12.5708ZM12.7896 19.3711C12.6759 17.2628 11.7852 15.271 10.2896 13.7808C8.80032 12.2822 6.80891 11.3883 4.69946 11.271C4.88557 9.5761 5.65434 7.99812 6.87451 6.80713C8.09469 5.61614 9.69075 4.88575 11.3896 4.74072C11.5348 6.79747 12.4234 8.73106 13.8896 10.1807C15.3414 11.638 17.2684 12.5252 19.3196 12.6807C19.1596 14.3682 18.4226 15.9491 17.2332 17.1567C16.0437 18.3644 14.4744 19.1254 12.7896 19.311V19.3711Z" />
  </SvgIcon>
);

export default TennisIcon;
