import type { SvgIconProps } from '@mui/material';
import { SvgIcon } from '@mui/material';

const BodybuildingIcon = ({ ...props }: SvgIconProps) => (
  <SvgIcon xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M6.76597 5.65489C6.76597 4.68428 7.605 3.99731 8.52748 3.99731H15.4965C16.419 3.99731 17.258 4.68429 17.258 5.65489V8.52644L17.2578 8.53505L16.658 8.51783C17.2578 8.53505 17.2578 8.53472 17.2578 8.53505L17.2577 8.53643L17.2577 8.53812L17.2575 8.54239L17.257 8.55453C17.2566 8.56419 17.2559 8.57698 17.2549 8.59275C17.253 8.62428 17.2496 8.66778 17.2442 8.72208C17.2332 8.83061 17.2136 8.98269 17.1786 9.16889C17.1085 9.54078 16.976 10.0523 16.7254 10.6261C16.2215 11.7797 15.2426 13.1767 13.3633 14.1849C12.5293 14.6324 11.4952 14.6324 10.6611 14.1851C8.77591 13.177 7.79657 11.7799 7.29395 10.6255C7.04396 10.0513 6.91261 9.53948 6.84351 9.16734C6.80891 8.98101 6.78975 8.82882 6.77916 8.72019C6.77386 8.66584 6.77069 8.62229 6.76881 8.59072C6.76786 8.57493 6.76724 8.56212 6.76684 8.55244L6.76637 8.54027L6.76624 8.53598L6.76619 8.53429C6.76618 8.53395 6.76616 8.5329 7.36597 8.51783L6.76616 8.5329L6.76597 8.52537V5.65489ZM7.96597 8.50565L7.96668 8.51928C7.96771 8.5365 7.96972 8.56501 7.97349 8.60369C7.98104 8.68114 7.99561 8.79893 8.02334 8.94826C8.0789 9.24746 8.18661 9.66969 8.39418 10.1464C8.80665 11.0938 9.61641 12.2657 11.2273 13.1271C11.7071 13.3845 12.3161 13.3849 12.796 13.1275C14.4013 12.2662 15.2115 11.094 15.6257 10.1458C15.8341 9.6687 15.9429 9.24616 15.9993 8.94671C16.0275 8.79725 16.0424 8.67934 16.0502 8.6018C16.0541 8.56307 16.0562 8.53451 16.0573 8.51725L16.058 8.50437V5.65489C16.058 5.4504 15.8646 5.19731 15.4965 5.19731H8.52748C8.1594 5.19731 7.96597 5.45042 7.96597 5.65489V8.50565ZM16.0584 8.49802C16.0584 8.498 16.0584 8.49798 16.0584 8.49802V8.49802Z"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M4.89249 6.30984C4.91489 5.77239 5.36004 5.35273 5.89203 5.35273H7.34583V6.45273H5.98952C5.97883 7.04446 6.00227 8.00182 6.33923 8.91056C6.68748 9.84977 7.36321 10.7261 8.72962 11.0997L8.43954 12.1607C6.66325 11.6751 5.7527 10.4927 5.30785 9.29299C4.87264 8.11929 4.8769 6.92023 4.89219 6.31876L4.89249 6.30984Z"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M19.1072 6.30984C19.0848 5.77239 18.6396 5.35273 18.1077 5.35273H16.6539V6.45273H18.0102C18.0208 7.04446 17.9974 8.00182 17.6605 8.91056C17.3122 9.84977 16.6365 10.7261 15.2701 11.0997L15.5601 12.1607C17.3364 11.6751 18.247 10.4927 18.6918 9.29299C19.127 8.11929 19.1228 6.92023 19.1075 6.31876L19.1072 6.30984Z"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M9.10203 17.5628V18.8028H14.912V17.5628H9.10203ZM7.90203 17.5628C7.90203 16.9014 8.44067 16.3628 9.10204 16.3628H14.912C15.5734 16.3628 16.112 16.9014 16.112 17.5628V19.3028C16.112 19.4692 16.054 19.6544 15.9088 19.7996C15.7636 19.9447 15.5784 20.0028 15.412 20.0028H8.60204C8.43569 20.0028 8.25045 19.9447 8.10529 19.7996C7.96012 19.6544 7.90203 19.4692 7.90203 19.3028V17.5628Z"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M10.6006 16.8428V13.9206H11.8006V16.8428H10.6006Z"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M12.4187 16.8428V13.9206H13.6187V16.8428H12.4187Z"
    />
  </SvgIcon>
);

export default BodybuildingIcon;
