import type { SvgIconProps } from '@mui/material';
import { SvgIcon } from '@mui/material';

const AllIcon = ({ ...props }: SvgIconProps) => (
  <SvgIcon xmlns="http://www.w3.org/2000/svg" viewBox="0 0 29 12" {...props}>
    <path d="M3.34375 11.5H0.8125L4.73438 0.1875H7.75L11.6562 11.5H9.14062L8.29688 8.89062H4.20312L3.34375 11.5ZM4.79688 7.0625H7.70312L6.28125 2.73438H6.20312L4.79688 7.0625ZM12.9375 11.5V0.1875H15.2812V9.5625H20.1562V11.5H12.9375ZM21.6719 11.5V0.1875H24.0156V9.5625H28.8906V11.5H21.6719Z" />
  </SvgIcon>
);

export default AllIcon;
