import type { SvgIconProps } from '@mui/material';
import { SvgIcon } from '@mui/material';

const SquashIcon = ({ ...props }: SvgIconProps) => (
  <SvgIcon xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" {...props}>
    <path d="M9.52075 10.3887C9.19627 10.3913 8.88598 10.5221 8.65747 10.7524C8.42896 10.9828 8.30077 11.2942 8.30078 11.6187C8.30078 11.9422 8.42916 12.2527 8.65796 12.4814C8.88675 12.7102 9.19719 12.8384 9.52075 12.8384C9.84524 12.8384 10.1566 12.7104 10.387 12.4819C10.6173 12.2534 10.7481 11.9431 10.7507 11.6187C10.7507 11.2924 10.6211 10.9797 10.3904 10.749C10.1597 10.5184 9.84697 10.3887 9.52075 10.3887Z" />
    <path d="M13.7808 10.3887C13.4563 10.3913 13.146 10.5221 12.9175 10.7524C12.689 10.9828 12.5605 11.2942 12.5605 11.6187C12.5605 11.7789 12.5923 11.9374 12.6536 12.0854C12.7149 12.2335 12.8047 12.3682 12.918 12.4814C13.0313 12.5947 13.1657 12.6843 13.3137 12.7456C13.4617 12.8069 13.6205 12.8384 13.7808 12.8384C13.941 12.8384 14.0995 12.8069 14.2476 12.7456C14.3956 12.6843 14.53 12.5947 14.6433 12.4814C14.7566 12.3682 14.8464 12.2335 14.9077 12.0854C14.969 11.9374 15.0007 11.7789 15.0007 11.6187C15.0021 11.4576 14.9714 11.2976 14.9106 11.1484C14.8499 10.9993 14.7604 10.8639 14.647 10.7495C14.5336 10.6352 14.3984 10.5444 14.2498 10.4824C14.1011 10.4205 13.9418 10.3887 13.7808 10.3887Z" />
    <path d="M11.6499 3C9.35659 3.00265 7.15799 3.915 5.53638 5.53662C3.91476 7.15824 3.00265 9.35659 3 11.6499C3.00265 13.9432 3.91476 16.1421 5.53638 17.7637C7.15799 19.3853 9.35659 20.2972 11.6499 20.2998C13.9432 20.2972 16.1418 19.3853 17.7634 17.7637C19.385 16.1421 20.2974 13.9432 20.3 11.6499C20.2974 9.35659 19.385 7.15824 17.7634 5.53662C16.1418 3.915 13.9432 3.00265 11.6499 3ZM11.6499 19C9.70137 18.9974 7.83339 18.2221 6.45557 16.8442C5.07775 15.4664 4.30269 13.5984 4.30005 11.6499C4.30269 9.70137 5.07775 7.83339 6.45557 6.45557C7.83339 5.07775 9.70137 4.30245 11.6499 4.2998C13.5984 4.30245 15.4664 5.07775 16.8442 6.45557C18.2221 7.83339 18.9974 9.70137 19 11.6499C18.9974 13.5984 18.2221 15.4664 16.8442 16.8442C15.4664 18.2221 13.5984 18.9974 11.6499 19Z" />
  </SvgIcon>
);

export default SquashIcon;
