import type { SvgIconProps } from '@mui/material';
import { SvgIcon } from '@mui/material';

const BasketballIcon = ({ ...props }: SvgIconProps) => (
  <SvgIcon xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" {...props}>
    <path d="M11.9995 3.38086C9.7062 3.38351 7.5076 4.29586 5.88599 5.91748C4.26437 7.5391 3.35226 9.73745 3.34961 12.0308C3.35226 14.3241 4.26437 16.5229 5.88599 18.1445C7.5076 19.7661 9.7062 20.678 11.9995 20.6807C14.2928 20.678 16.4914 19.7661 18.113 18.1445C19.7347 16.5229 20.647 14.3241 20.6497 12.0308C20.647 9.73745 19.7347 7.5391 18.113 5.91748C16.4914 4.29586 14.2928 3.38351 11.9995 3.38086ZM19.3496 12.0308C19.3496 12.1008 19.3496 12.1707 19.3496 12.2407C17.7929 12.5197 16.3393 13.2103 15.1396 14.2407L12.8196 11.9209L17.5396 7.20068C18.7013 8.53044 19.3441 10.2352 19.3496 12.001V12.0308ZM16.7195 17.6606C15.8157 18.419 14.7419 18.9475 13.5896 19.2007C13.8607 18.0568 14.3949 16.9921 15.1497 16.0908L16.7195 17.6606ZM16.5896 6.30078L11.8896 11.001L9.61963 8.73096C10.6014 7.58788 11.2698 6.20965 11.5596 4.73096H11.9995C13.66 4.70802 15.2789 5.25119 16.5896 6.271V6.30078ZM7.75952 8.71094C6.92367 9.41201 5.94399 9.92044 4.88965 10.2007C5.1618 9.14224 5.66749 8.15828 6.36963 7.3208L7.75952 8.71094ZM7.28955 6.40088C8.12961 5.70264 9.11265 5.19742 10.1694 4.9209C9.88919 5.97524 9.38052 6.95516 8.67944 7.79102L7.28955 6.40088ZM4.66943 11.5908C6.14813 11.301 7.52636 10.6327 8.66943 9.65088L10.9395 11.9209L6.2395 16.6211C5.19101 15.3211 4.61943 13.7009 4.61963 12.0308C4.64963 11.8508 4.66943 11.711 4.66943 11.561V11.5908ZM7.17944 17.5908L11.8896 12.8711L14.2095 15.1807C13.1787 16.384 12.4882 17.8411 12.2095 19.4009H11.9995C10.2182 19.3883 8.50321 18.7228 7.17944 17.5308V17.5908ZM17.6396 16.771L16.0696 15.2007C16.9689 14.4451 18.0347 13.9141 19.1794 13.6509C18.915 14.7782 18.3875 15.8269 17.6396 16.7109V16.771Z" />
  </SvgIcon>
);

export default BasketballIcon;
