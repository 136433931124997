import type { SvgIconProps } from '@mui/material';
import { SvgIcon } from '@mui/material';

const HandballIcon = ({ ...props }: SvgIconProps) => (
  <SvgIcon xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M5.52173 5.52197C7.13397 3.90973 9.3198 3.00265 11.5999 3C13.8799 3.00265 16.0657 3.90973 17.678 5.52197C19.2902 7.13421 20.1973 9.32005 20.2 11.6001C20.1973 13.8801 19.2902 16.066 17.678 17.6782C16.0657 19.2905 13.8799 20.1975 11.5999 20.2002C9.3198 20.1975 7.13397 19.2905 5.52173 17.6782C3.90949 16.066 3.00265 13.8801 3 11.6001C3.00265 9.32005 3.90949 7.13421 5.52173 5.52197ZM18.99 11.4199C18.9708 10.4547 18.7599 9.50319 18.3699 8.62012V9.89014L18.99 11.4199ZM17.2698 9.47021V7.47021L15.48 6.18994L14.3799 7.47021L15.5198 9.47021H17.2698ZM13.2998 12.2002L14.5698 10L13.2998 7.7998H10.7598L9.48999 10L10.7598 12.2002H13.2998ZM11.5999 4.2002C11.0753 4.20126 10.5523 4.25817 10.0398 4.37012L9.32983 5.58984L10.6599 6.72998H13.6099L14.6099 5.56006L13.8699 4.56006C13.1368 4.3216 12.3707 4.20025 11.5999 4.2002ZM8.35343 5.02618C7.72697 5.31965 7.14364 5.69767 6.61987 6.1499L8.20996 5.27002L8.35343 5.02618ZM8.5498 6.33984L6.5498 7.43994L6.34985 9.41992H8.5498L9.75 7.33984L8.5498 6.33984ZM8.53979 10.5498H6.10986L5.40991 12.9702L7.02979 15.29H8.90991L9.83984 12.7998L8.53979 10.5498ZM5.35986 7.6499C4.60431 8.82896 4.2019 10.1997 4.19995 11.6001V11.6201C4.20263 12.0192 4.23929 12.4173 4.30981 12.8101L5.20996 9.72021L5.41992 7.6499H5.35986ZM4.46997 13.5498C4.75258 14.5912 5.26131 15.5575 5.95996 16.3799L6.12988 15.9199L4.46997 13.5498ZM11.5667 18.5143L10.7832 18.9544C9.32671 18.7916 7.945 18.1979 6.81982 17.2402L7.12988 16.3901H9.12988L11.5398 17.52L11.5667 18.5143ZM10.8298 13.2998L9.98999 15.5601L12.0898 16.54L13.99 15.2998L13.24 13.2998H10.8298ZM12.6799 18.4302L13.3198 18.79V18.8101C14.6117 18.4909 15.7914 17.8232 16.73 16.8799V16.0601H14.8298L12.6799 17.48V18.4302ZM15.0398 14.9702H16.8198L18.2 12.3999L17.45 10.5498H15.5198L14.22 12.7998L15.0398 14.9702ZM17.8198 15.46V15.6099L17.8098 15.6299C18.1523 15.1064 18.4279 14.5423 18.6299 13.9502L17.8198 15.46Z"
    />
  </SvgIcon>
);
export default HandballIcon;
